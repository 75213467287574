import * as math from "mathjs";
import _ from "lodash";
import { AssetClasses } from "apiclient/AssetClasses";
import { getMatrixRow } from "util/misc-utils";
import { AssetWeights } from "./AssetWeights";
import { AssetClassReturns } from "apiclient/AssetClassReturns";
import { FundReturns } from "apiclient/FundReturns";
import IStringNumberMap from "util/StringNumberMap";
import Allocation from "./Allocation";
import DateReturn from "./DateReturn";
import { AssetClassData } from "apiclient/responsePayloads/AssetClass";
import { IFundAllocationOverrides, FundAllocation } from "./FundAllocation";
import { isFundIdIndexFund } from "apiclient/responsePayloads/Fund";

class AssetClassPortfolio {
  public readonly index?: number;
  public readonly assetClasses: AssetClasses;
  public readonly allocations: Allocation[];
  public readonly covarianceMap: any; // Todo: Replace any type with real type
  public readonly weights: AssetWeights;
  private readonly covarianceMatrix: math.Matrix;
  private readonly fundAllocationOverrides: IFundAllocationOverrides;

  constructor(weights: AssetWeights, assetClasses: AssetClasses, index?: number, fundAllocationOverrides: IFundAllocationOverrides = {}) {
    if (!weights.isValid()) {
      throw new Error(`Sum of weights must be 100 or 0, was ${weights.total()}!`);
    }

    this.index = index;
    this.assetClasses = assetClasses;
    this.weights = this.validateWeights(weights, assetClasses);
    this.allocations = assetClasses.data.map(x => new Allocation(x, weights.get(x.id), weights.total()));
    this.covarianceMatrix = this.calculateCovarianceMatrix(this.correlationMapToMatrix(this.getCorrelationMap()));
    this.covarianceMap = this.covarianceMatrixToMap(this.covarianceMatrix);
    this.fundAllocationOverrides = fundAllocationOverrides;
  }

  get isCustom(): boolean {
    return this.index === undefined;
  }

  private validateWeights(weights: AssetWeights, assetClasses: AssetClasses): AssetWeights {
    const sortedWeightsIDs = Object.keys(weights.data).sort();
    const sortedAssetClassesIDs = assetClasses.data.map(ac => ac.id).sort();

    if (_.isEqual(sortedWeightsIDs, sortedAssetClassesIDs)) {
      return weights;
    } else {
      sortedAssetClassesIDs.forEach(ac => {
        if (!sortedWeightsIDs.find(w => w === ac)) weights.data[ac] = 0;
      });
      return weights;
    }
  }

  public calculateRisk(): number {
    const W = this.getNormalizedWeightMatrix();
    const M = math.multiply(math.multiply(W, this.covarianceMatrix), math.transpose(W));
    const risk = math.sqrtm(M);
    return Number(math.subset(risk, math.index(0, 0)));
  }

  public calculateYield(): number {
    const assetYieldArray = this.allocations.map((allocation: any) => allocation.assetClass.yield);
    const pYield = math.multiply(this.getNormalizedWeightMatrix(), math.transpose([assetYieldArray]));
    return Number(math.subset(pYield, math.index(0, 0)));
  }

  public calculateAssetClassReturnHistory(assetClassReturns: AssetClassReturns): DateReturn[] {
    const data: DateReturn[] = [];
    for (const assetClassReturn of assetClassReturns.data) {
      let returnForDate = 0;
      for (const allocation of this.allocations) {
        const assetReturn = _.get(assetClassReturn.returns, allocation.assetClass.id, 0.0);
        returnForDate += assetReturn * allocation.normalizedWeightInPercent * 0.01;
      }
      data.push(new DateReturn(assetClassReturn.date, returnForDate));
    }
    return data;
  }

  public calculateFundReturnHistory(fundReturns: FundReturns, assetClassReturns: AssetClassReturns): DateReturn[] {
    const data: DateReturn[] = [];
    const fundWeights: FundAllocation = this.getFundAllocation();

    for (const fundReturn of fundReturns.data) {
      let returnForDate = 0;
      Object.keys(fundWeights.funds).forEach(fundID => {
        const fundWeight = 0.01 * _.get(fundWeights.funds, fundID, 0.0);
        returnForDate += fundWeight * _.get(fundReturn.returns, fundID, 0.0);
      });
      data.push(new DateReturn(fundReturn.date, returnForDate));
    }

    return data;
  }

  /** Convenience method that calculates fund return history if fundsReturns are specified, otherwise asset class history */
  public calculateReturnHistory(assetClassReturns: AssetClassReturns, fundReturns?: FundReturns) {
    return fundReturns !== undefined
      ? this.calculateFundReturnHistory(fundReturns, assetClassReturns)
      : this.calculateAssetClassReturnHistory(assetClassReturns);
  }

  public getFundAllocation(splitAmong?: "Indexes" | "Funds"): FundAllocation {
    return this.allocations.reduce((aggregateAllocation: FundAllocation, allocation: Allocation) => {
      let isSplitAmongSent = false;
      let fundAllocationForAC = this.getFundAllocationForAssetClass(allocation.assetClass, allocation.normalizedWeightInPercent);

      if (splitAmong === "Indexes" || splitAmong === "Funds") isSplitAmongSent = true;

      if (isSplitAmongSent) {
        if (splitAmong === "Indexes")
          fundAllocationForAC = this.getFundAllocationForAssetClass(allocation.assetClass, allocation.normalizedWeightInPercent, "Indexes");
        else if (splitAmong === "Funds")
          fundAllocationForAC = this.getFundAllocationForAssetClass(allocation.assetClass, allocation.normalizedWeightInPercent, "Funds");
      }

      return aggregateAllocation.add(fundAllocationForAC);
    }, new FundAllocation());
  }

  public getFundAllocationForAssetClass(
    assetClass: AssetClassData,
    assetClassWeightInPercent: number,
    splitAmong?: "Indexes" | "Funds"
  ): FundAllocation {
    // If the portfolio has manually overriden the fund allocations for this
    // asset class, use that.
    const overriddenAllocation = this.getOverridenFundAllocation(assetClass, assetClassWeightInPercent);
    if (overriddenAllocation !== null && splitAmong === undefined) {
      return overriddenAllocation;
    }

    const numberOfNonIndexFunds = assetClass.funds.filter(fundId => !isFundIdIndexFund(fundId)).length;

    const ret: FundAllocation = new FundAllocation();

    if (splitAmong === "Funds") {
      // Distribute evenly across available non-index funds.
      for (const fundID of assetClass.funds) {
        if (!isFundIdIndexFund(fundID)) {
          ret.funds[fundID] = assetClassWeightInPercent / numberOfNonIndexFunds;
        } else {
          ret.funds[fundID] = 0;
        }
      }
    } else if (splitAmong === "Indexes") {
      // Otherwise, distribute evenly across available index funds.
      for (const fundID of assetClass.funds) {
        if (isFundIdIndexFund(fundID)) {
          ret.funds[fundID] = assetClassWeightInPercent;
        } else {
          ret.funds[fundID] = 0;
        }
      }
    } else {
      for (const fundID of assetClass.funds) {
        if (isFundIdIndexFund(fundID)) {
          ret.funds[fundID] = 0;
        } else {
          ret.funds[fundID] = assetClassWeightInPercent / numberOfNonIndexFunds;
        }
      }
    }
    return ret;
  }

  public getOverridenFundAllocation(assetClass: AssetClassData, assetClassWeightInPercent: number): FundAllocation | null {
    if (!(assetClass.id in this.fundAllocationOverrides)) {
      return null;
    }
    const overriddenFundAllocation = this.fundAllocationOverrides[assetClass.id];
    if (!overriddenFundAllocation.verifyFundsMatchingAssetClass(assetClass)) {
      // tslint:disable-next-line:no-console
      console.log(`Fund allocation overrides for ${assetClass.id} does not match asset class funds.`);
      return null;
    }
    return overriddenFundAllocation.normalizedTo(assetClassWeightInPercent);
  }

  private getCorrelationMap() {
    return new Map(this.assetClasses.data.map(x => [x.id, x.correlations]) as any);
  }

  private getAssetRiskMatrix(): math.Matrix {
    return math.matrix([this.allocations.map((allocation: Allocation) => allocation.assetClass.risk)]);
  }

  private getNormalizedWeightMatrix(): math.Matrix {
    return math.matrix([this.allocations.map(x => x.normalizedWeightInPercent * 0.01)]);
  }

  private calculateCovarianceMatrix(correlationMatrix: math.Matrix): math.Matrix {
    const M = this.getAssetRiskMatrix();
    const MM = math.multiply(math.transpose(M), M);
    return math.dotMultiply(MM, correlationMatrix) as math.Matrix;
  }

  private correlationMapToMatrix(correlationMap: any): math.Matrix {
    return math.matrix(this.assetClasses.data.map(x => this.assetClasses.data.map(y => correlationMap.get(x.id)[y.id])));
  }

  private covarianceMatrixToMap(covarianceMatrix: math.Matrix) {
    return new Map(this.assetClasses.data.map((x, i) => [x.id, getMatrixRow(covarianceMatrix, i)]) as any);
  }
}

function createEmptyPortfolio(assetClasses: AssetClasses): AssetClassPortfolio {
  const weightsInPercent: IStringNumberMap = {};
  for (const assetClass of assetClasses.data) {
    weightsInPercent[assetClass.id] = 0.0;
  }
  const weights = new AssetWeights(weightsInPercent);
  return new AssetClassPortfolio(weights, assetClasses);
}

export { AssetClassPortfolio, createEmptyPortfolio };
