import axios, { AxiosResponse } from "axios";
import { jwtDecode } from "jwt-decode";

type AuthErrKind = "Duplicate" | "Other";

export class AuthError {
  public kind: AuthErrKind;
  public source: Error;
  public message: string;

  constructor(kind: AuthErrKind, source: Error, message: string) {
    this.kind = kind;
    this.source = source;
    this.message = message;
  }
}

class Auth {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  public isLoggedIn(): boolean {
    return !!this.storage.getItem("jwtToken");
  }

  private getClaims(): any {
    const token = this.storage.getItem("jwtToken");
    if (token !== null) {
      return jwtDecode(token);
    }
    return null;
  }

  public getUserID = () => this.getStringClaim("uid");
  public getEmail = () => this.getStringClaim("email");
  public getFirstName = () => this.getStringClaim("fname");
  public getLastName = () => this.getStringClaim("lname");

  public login(email: string, password: string) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/auth/login", { email: email.toLowerCase(), password: password })
        .then((result: AxiosResponse) => {
          this.storage.setItem("jwtToken", result.data.token);
          resolve(result.data.token);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public logout(): void {
    this.storage.removeItem("jwtToken");
  }

  public register(email: string, password: string, firstName?: string, lastName?: string, organization?: string) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/users", {
          email: email,
          password: password,
          firstName: firstName,
          lastName: lastName,
          organization: organization
        })
        .then((result: AxiosResponse) => {
          if (result.data.token) {
            this.storage.setItem("jwtToken", result.data.token);
          }
          resolve(result.data);
        })
        .catch(error => {
          if (error && error.response && error.response.statusCode === 409) {
            // Status code for conflict -- it's a duplicate
            reject(new AuthError("Duplicate", error, "A user with that email already exist"));
          } else {
            reject(new AuthError("Other", error, error.message));
          }
        });
    });
  }

  private getStringClaim(field: string): string | undefined {
    const claims = this.getClaims();
    if (claims instanceof Object && typeof claims[field] === "string") {
      return claims[field];
    }
    return undefined;
  }
}

export default Auth;
